
// ページ全体の横幅
$pc_width: 1100px;
$sp_width: 767px;

$pc_fixwidth: 1200px;


@mixin mq_sp{
	@media screen and (max-width: $sp_width) {
		@content;
	}
}

@mixin mq_tbonly{
	@media screen and (min-width: $sp_width+1px) and (max-width: $pc_width) {
		@content;
	}
}
@mixin mq_tbfix{
	@media screen and (min-width: $pc_width+1px) and (max-width: $pc_fixwidth) {
		@content;
	}
}


@mixin mq_tbsp{
	@media screen and (max-width: $pc_width) {
		@content;
	}
}

@mixin mq_tb{
	@media screen and (min-width: $sp_width +1px) {
		@content;
	}
}

@mixin mq_pc{
	@media screen and (min-width: $pc_width +1px) {
		@content;
	}
}

@mixin mq_pc_1450px{
  @media screen and (max-width: 1450px) {
    @content;
  }
}

@mixin mq_pcl{
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin mq_pcw{
  @media screen and (min-width: 2560px) {
    @content;
  }
}


//kondo original
@mixin fuwari($tm) {
	@include transition(all $tm linear);
}
@mixin fuwari_d($tm,$delay) {
	@include transition(all $tm linear $delay );
}

@mixin tb_center{
	// text-transform: translateY(-50%);
	@include translateY(-50%);
	position: absolute;
	top: 50%;
}

@mixin pc{
	display:inline-block;
	@include mq_sp {
		display: none;
	}
}

@mixin sp{
	display:none;
	@include mq_sp {
		display: inline-block;
	}
}

@mixin noto {
	font-family: $noto-font;
}
// @import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@mixin josefin {
	font-family: 'Josefin Sans', sans-serif;
}
@mixin josefin_b {
	font-family: 'Josefin Sans', sans-serif;
	font-weight:bold;
}
// ._josefin {
// 	@include josefin;
// }
@mixin yugo {
	font-family:"Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
}
@mixin flexcenter {
	@include flexbox;
	@include flex-wrap(wrap);
	@include justify-content(space-between);
}



//font sizing
@mixin fs_ll {
	font-size:2.8rem;
}
@mixin fs_l {
	font-size:2.2rem;
}
@mixin fs_m {
	font-size:1.6rem;
}
@mixin fs_s {
	font-size:1.4rem;
}

@mixin fss_ll {
	font-size:2.8rem;
}
@mixin fss_l {
	font-size:2.2rem;
}
@mixin fss_m {
	font-size:1.6rem;
}
@mixin fss_s {
	font-size:1.5rem;
}

@mixin lh {
	line-height: 1.7;
}

@mixin font_mp {
	font-family: 'M PLUS Rounded 1c', sans-serif;
}
@mixin font_deli {
  font-family: 'Delius Unicase', cursive;
}
.txt_base {
	@include fs_m;
	@include lh;
}


@mixin boxshadow {
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,.1);
}
@mixin flexcenter {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	
}

//  @include gradient(top,bottom,transparent,$color_7,280px);
@mixin gradient($start,$end,$startcolor,$endcolor,$percent) {
      background: $startcolor; /* Old browsers */
      background: -moz-linear-gradient($start, $startcolor 0%, $startcolor $percent, $endcolor $percent, $endcolor 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient($start, $startcolor 0%,$startcolor $percent,$endcolor $percent,$endcolor 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to $end, $startcolor 0%,$startcolor $percent,$endcolor $percent,$endcolor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startcolor', endColorstr='$endcolor',GradientType=0 ); /* IE6-9 */        
}


//=================================
// font-style heading
//=================================

@mixin h_size_18_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 18px;
}

@mixin h_size_30_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 31px;
}

@mixin h_size_31_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 31px;
}

@mixin h_size_32_blue_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: $blue;
    font-size: 31px;
}

@mixin h1_size_52_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 52px;
}

@mixin h2_size_52_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 52px;
}

@mixin h2_size_50_blue_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: $blue;
    font-size: 50px;
}

@mixin h2_size_52_blue_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: $blue;
    font-size: 3.2rem;

    @include mq_pc {
        font-size: 52px;
    }
}

//=================================
// font-style p
//=================================

@mixin p_size_14_black {
    color: $black;
    font-size: 14px;
    font-weight: 100;
    text-align: left;
}

@mixin p_size_14_black_center {
    text-align: center;
    color: $black;
    font-size: 14px;
    font-weight: $bold;
    line-height: 35px;
}

@mixin p_size_16_black {
    color: $black;

    @include mq_pc {
        font-size: 16px;
        font-weight: $bold;
    }


    font-size: 1.6rem;
    font-weight: $regular;
}

@mixin p_size_16_black_center {
    text-align: center;
    color: $black;


    font-size: 1.6rem;
    font-weight: $regular;
    line-height: 35px;
}

@mixin p_size_16_black_right {
    text-align: right;
    color: $black;
    font-size: 1.4rem;

    @include mq_pc {
        font-size: 1.6rem;
    }
}

@mixin p_size_16_fff_center {
    text-align: center;
    color: #fff;
    font-size: 16px;
}

@mixin p_size_16_blue_center {
    text-align: center;
    color: $blue;
    font-size: 16px;
    font-weight: $bold;
    line-height: 35px;
}

@mixin p_size_18_black {
    color: $black;
    font-size: 18px;
    font-weight: $bold;
    text-align: left;
}

@mixin p_size_20_fff_center {
    font-weight: $bold;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

@mixin p_size_20_black {
    font-weight: $bold;
    color: $black;
    font-size: 20px;
}

@mixin p_size_20_black_center {
    text-align: center;
    font-weight: $bold;
    color: $black;
    font-size: 20px;
}

@mixin p_size_26_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 26px;
}

@mixin p_size_26_blue_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: $blue;
    font-size: 26px;
}

@mixin p_size_30_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 30px;
}

@mixin p_size_32_blue_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: $blue;
    font-size: 32px;
}

@mixin p_size_42_black_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: $black;
    font-size: 42px;
}

@mixin p_size_42_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;

    @include mq_pc {
        font-size: 42px;
    }


    line-height: 1.2;
    font-size: 2rem;
}

@mixin p_size_52_fff_center {
    font-family: $M_PLUS_Rounded_1c_font;
    font-weight: $bold;
    text-align: center;
    color: #fff;
    font-size: 52px;
}