/* ======================================================================
 setting
====================================================================== */

$supported-browsers: ('chrome', 'safari', 'firefox', 'ie');
$graceful-usage-threshold: 0.1;
$critical-usage-threshold: 0.01;
$browser-minimum-versions: ('firefox': '15', 'ie': '8');

// 画像のパス
// $img_path: '../../img/';
// $img_path2: '../img/';

// ページ全体の横幅
$base_width: 1100px;

// メイン横幅
$main_width: 900px;

// サイド横幅
$side_width: 250px;
$side_width: 250px;
$sidesp: 15px;
// 付与するベンダープレフィックス
// $set_prefix: -webkit-, -moz-, -ms-, -o-, '';

//=================================
// font
//=================================

// 基本フォント
$gothic_font: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'hiragino kaku gothic pro', 'メイリオ', Meiryo, '游ゴシック', YuGothic, Verdana, sans-serif;
$min_font: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', '游明朝', YuMincho, 'HG明朝E', 'MS P明朝', 'MS 明朝', serif;
$M_PLUS_Rounded_1c_font:'M PLUS Rounded 1c', sans-serif, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, '游ゴシック', YuGothic, Verdana;

// google font
$noto_sans_font: 'Noto Sans', sans-serif, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, '游ゴシック', YuGothic, Verdana;

// font-face
// @include font-face('name01', font-files('name01/name01.woff', 'name01/name01.ttf'), 'name01/name01.eot');
// @include font-face('name02', font-files('name02/name02.woff', 'name02/aname02.ttf'), 'name02/name02.eot');

//font size: 
$thin:100;
$light:300;
$regular:400;
$medium:500;
$bold:700;
$ex_bold:800;
$super_bold:900;


//=================================
// color
//=================================
//white
$color_w: #fff;
//black
$color_1: #202020;
//yellow green
$color_2: #FDC325;
//yellow
$color_3: #FFE034;
//list mouseover
$color_4: #FFF9BA;
//conversion pink
//pink
$color_5: #E3556A;
//blue
$color_6: #00A4FF;
//gray
$color_7: #868686;

//hover yellow
$color_8: #FFF9BA;

// gray の背景
$color_9: #F4F4F4;

// grayボーダー
$color_10: #DCDCDC;
//濃いあお
$color_11: #006BB8;

$color_12: #FFFABA;

//薄い水色
$color_13: #BBE3FD;

//テーブルボーダー
$color_14: #c7c7c7;
//文字 light blue
$color_15: #00A4FF;

$color_16: #E8F7FF;

$color_17: #000;

$color_18: #000;

$color_19: #000;

$color_20: #000;

$color_w:#fff;

$pink: #E3556A;

$blue:#00A4FF;

$black:#202020;

$yellow:#FFE034;

$lightblue:#EFF9FF;

$light_pink:#FDE7EA;

$dark_pink:#DD3951;

$dark_blue:#0076FF;
//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
// In
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
// Out
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);
