@import 'compass';
@import 'compass/support';

@import 'setting';
@import 'kond';
@import 'mixin';

/* ======================================================================
 p_voice
====================================================================== */
.u_mv .innerwrap .mv_ttl {
  @include mq_pc {
    width: 750px;
  }
}

.sec1 {
  padding: 80px 0 100px;
  @include gradient(top,bottom,$color_3,transparent,450px);
  .roundtop_wrap {
    background:$color_w;
    @include mq_pc {
      padding: 40px 200px;
      text-align:center;
    }
    padding: 30px 15px;
  	border-radius:10px;
    .list {
      @include mq_sp {
        margin: 20px 0;
      }
      .item {
        text-align:left;
        position: relative;
        @include mq_sp {
          margin-left: 2.5em;
        }
        margin-left: 12em;
        &:before {
          content:"・";
          position: absolute;
          left: -1em;
          @include translateY(-.3em);
          font-size: 2rem;
          color:$color_15;
        }
      }
    }
  }
}